.select__input{
    
    background: 0px center;
    opacity: 1;
    width: 100%;
    grid-area: 1 / 2 / auto / auto;
    font: inherit;
    min-width: 0px !important;
    border: 0px;
    margin: 0px;
    outline: 0px;
    padding: 0px;

}


.select__input:focus{   
     --tw-ring-offset-width: 0px;
     --tw-ring-color: transparent;
}